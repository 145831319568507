<template>
    <div id="investor-relations">
        <div class="content">
            <div :class="['baseinfo', 'animate__animated', 'animate__fadeInUp']">
                <h2 class="badge" v-if="jbxx">{{ isEn ? jbxx.enName : jbxx.cnName }}</h2>
                <p v-html="(isEn ? jbxx.data.enContent : jbxx.data.cnContent)" v-if="jbxx"></p>
                <div class="k-line">
                    <img :src="klineUrl" alt="">
                </div>
            </div>
            <div :style="{'visibility': aniObj.announcementVisible ? 'visible' : 'hidden'}" :class="['announcement', 'animate__animated', aniObj.announcement ? 'animate__fadeInUp' : '']" ref="announcement">
                <h2 class="badge" v-if="gsgg">{{ isEn ? gsgg.enName : gsgg.cnName }}</h2>
                <div class="list" v-if="gsgg">
                    <ul>
                        <li v-for="(item, index) in gsgg.data" :key="index">
                            <div class="date">
                                {{ item.createTime | formatYearMonth }}
                                <p>{{ item.createTime | formatDay }}</p>
                            </div>
                            <div class="text">
                                <div v-html="isEn ? item.enTitle : item.cnTitle"></div>
                                <span class="icon-more" @click="toSubpage(item.redirectUrl)"></span>
                            </div>
                        </li>
                    </ul>
                    <span class="btn-more" @click="toSubpage(gsgg.templateUrl)">{{ $t('lang.see_more') }}</span>
                </div>
            </div>

            <div :style="{'visibility': aniObj.companyVisible ? 'visible' : 'hidden'}" :class="['company-management', 'animate__animated', aniObj.company ? 'animate__fadeInUp' : '']" ref="company">
                <h2 class="badge" v-if="gszl">{{ isEn ? gszl.enName : gszl.cnName }}</h2>
                <ul v-if="gszl">
                    <li>
                        <img :src="require('@/assets/images/about/pic_117.png')" alt="">
                    </li>
                    <li>
                        <h2>{{ isEn ? gszl.data[0].enName : gszl.data[0].cnName }}</h2>
                        <p>
                            <span @click="toSubpage(item.redirectUrl)" v-for="(item, index) in gszl.data[0].data" :key="index">{{ isEn ? item.enTitle : item.cnTitle }}</span>
                        </p>
                        <span class="icon-more" @click="toSubpage(gszl.data[0].templateUrl)"></span>
                    </li>
                    <li>
                        <h2>{{ isEn ? gszl.data[1].enName : gszl.data[1].cnName }}</h2>
                        <p>
                            <span @click="toSubpage(item.redirectUrl)" v-for="(item, index) in gszl.data[1].data" :key="index">{{ isEn ? item.enTitle : item.cnTitle }}</span>
                        </p>
                        <span class="icon-more" @click="toSubpage(gszl.data[1].templateUrl)"></span>
                    </li>
                    <li>
                        <img :src="require('@/assets/images/about/c972f0b19b83309bb7b9ca0021878161.png')" alt="">
                    </li>
                </ul>
                <div v-if="gszl" class = "h5">
                    <div>
                        <em>
                            <img :src="require('@/assets/images/about/pic_117.png')" alt="">
                        </em>
                        <h2>{{ isEn ? gszl.data[1].enName : gszl.data[1].cnName }}</h2>
                        <p>
                            <span @click="toSubpage(navsType[2], 2)" v-for="(item, index) in gszl.data[1].data" :key="index">{{ isEn ? item.enTitle : item.cnTitle }}</span>
                        </p>
                        <span class="icon-more" @click="toSubpage(navsType[2], 2)">
                            {{ $t('lang.see_more') }}
                        </span>
                    </div>
                    <div>
                        <em>
                            <img :src="require('@/assets/images/about/c972f0b19b83309bb7b9ca0021878161.png')" alt="">
                        </em>
                        <h2>{{ isEn ? gszl.data[0].enName : gszl.data[0].cnName }}</h2>
                        <p>
                            <span @click="toSubpage(navsType[2], 1)" v-for="(item, index) in gszl.data[0].data" :key="index">{{ isEn ? item.enTitle : item.cnTitle }}</span>
                        </p>
                        <span class="icon-more" @click="toSubpage(navsType[2], 1)">
                            {{ $t('lang.see_more') }}
                        </span>
                    </div>
                </div>
            </div>

            <div :style="{'visibility': aniObj.otherVisible ? 'visible' : 'hidden'}" :class="['other', 'animate__animated', aniObj.other ? 'animate__fadeInUp' : '']" ref="other">
                <ul v-if="redirect">
                    <li v-for="(item, index) in redirect" :key="index">
                        <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" alt="">
                        <h2>{{ isEn ? item.enName : item.cnName }}</h2>
                        <span @click="toSubpage(item.templateUrl)" class="icon-more"></span>
<!--                        <router-link :to="item.templateUrl" class="icon-more"></router-link>-->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import {urls, staticPath} from '@/lib/common'
    export default {
        name: "InvestorRelations",
        components: {},
        data() {
            return {
                staticPath,
                klineUrl: 'http://image.sinajs.cn/newchart/daily/n/sh603398.gif',
                stockData: '',
                isIncrease: false,
                timer: null,
                isEn: false,
                jbxx: '',
                gsgg: '',
                gszl: '',
                redirect: '',
                navId: '',
                //        基本信息，  公司公告         公司治理         股东回报           保护投资者            互动交流         投资者教育
                navsType: ['info', 'announcement', 'governance', 'reciprocation', 'investorProtection', 'interactive', 'investorEducation'],

                announcementScrollTop: '',
                companyScrollTop: '',
                otherScrollTop: '',
                aniObj: {
                    announcementVisible: false,
                    announcement: false,
                    companyVisible: false,
                    company: false,
                    otherVisible: false,
                    other: false
                }

            };
        },
        computed: {
            execIncrease() {
                return (this.stockData[3] - this.stockData[2]) / this.stockData[2] * 100;
            }
        },
        watch: {
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        beforeDestroy() {
            // 清除K线图定时器
            clearInterval(this.timer);
            this.timer = null;
        },
        methods: {
            getTemplate() {
                return this.$http.get(urls.getInvestorTemplate + this.navId).then(res => {
                    if (res.data.status === 200) {
                        const data = res.data.data;
                        this.jbxx = data.jbxx;
                        this.gsgg = data.gsgg;
                        this.gszl = data.gszl;
                        this.redirect = data.redirect;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            toSubpage(url) {
                window.open(url);
                // const id = encodeURIComponent(btoa(this.navId));
                // let query = {
                //     n: id
                // }
                // if (type) {
                //     query.t = encodeURIComponent(btoa(type))
                // }
                // this.$router.push({
                //     path: '/c/investorRelations/subpage/' + page,
                //     query
                // })
            },

            // 监听页面滚动
            getScrollTop() {
                var scroll_top = 0;
                if (document.documentElement && document.documentElement.scrollTop) {
                    scroll_top = document.documentElement.scrollTop;
                } else if (document.body) {
                    scroll_top = document.body.scrollTop;
                }
                return scroll_top;
            },
            listenPageScrollFun() {
                document.addEventListener('scroll', this.zoomControl, true)
            },
            zoomControl() {
                var diffValue = -(document.body.clientHeight * 0.5);
                this.winScrollHeight = this.getScrollTop();
                //if (this.announcementScrollTop < this.winScrollHeight + 600) {
                if (!this.aniObj.announcement && this.winScrollHeight >= this.$refs.announcement.offsetTop + diffValue) {
                    this.aniObj.announcementVisible = true;
                    this.aniObj.announcement = true;
                }
                //if (this.companyScrollTop < this.winScrollHeight + 650) {
                if (!this.aniObj.company && this.winScrollHeight >= this.$refs.company.offsetTop + diffValue) {
                    this.aniObj.companyVisible = true;
                    this.aniObj.company = true;
                }
                //if (this.otherScrollTop < this.winScrollHeight + 650) {
                if (!this.aniObj.other && this.winScrollHeight >= this.$refs.other.offsetTop + diffValue) {
                    this.aniObj.otherVisible = true;
                    this.aniObj.other = true;
                }
            }
        },
        beforeDestroy() {
            document.removeEventListener('scroll', this.zoomControl, true)
        },
        mounted() {
            // 刷新K线图
            this.timer = setInterval(() => {
                this.klineUrl = '';
                this.klineUrl = 'http://image.sinajs.cn/newchart/daily/n/sh603398.gif';
            }, 30000)

            this.navId = decodeURIComponent(atob(this.$route.query.n));

            this.listenPageScrollFun()
            this.getTemplate().then(() => {
                this.announcementScrollTop = this.$refs.announcement.offsetTop;
                this.companyScrollTop = this.$refs.company.offsetTop;
                this.otherScrollTop = this.$refs.other.offsetTop;
            })
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    #investor-relations {
        color: #34254C;
        .icon-more {
            display: inline-block;
            width: 1.458vw;
            height: 0.9375vw;
            cursor: pointer;
            background: url('../../assets/images/about/icon_more.png') no-repeat center center;
            background-size: 100% 100%;
        }
        .content {
            padding-top: 1px;
            background: url('../../assets/images/investor/index_bg.png') no-repeat center -640px;
            background-size:1920px auto;
            .badge {
                font-size: 28px;
                text-align: center;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 45px;
                    height: 38px;
                    background: url('../../assets/images/about/icon.png') no-repeat center center;
                    background-size: 100% 100%;
                    position: relative;
                    top: -1.3vw;
                    right: 0.6vw;
                }
            }
            .btn-more {
                display: block;
                width: 220px;
                height: 70px;
                line-height: 70px;
                margin: 60px auto;
                font-size: 20px;
                border-radius: 90px;
                text-align: center;
                background: linear-gradient(to left top, #dc000c, #ff4b7c);
                color: #fff;
                cursor: pointer;
                box-sizing: border-box;
                box-shadow: 0 20px 30px rgba(247, 144, 170, 0.49);
            }
            >div {
                width: 90%;
                max-width: 1200px;
                margin: 4vw auto;
            }
            .baseinfo {
                >p {
                    font-size: 18px;
                    line-height: 2.6vw;
                    margin-top: 2vw;
                }
                .k-line {
                    width:1000px;
                    margin: 5vw auto;
                    background: #f3fcff;
                    padding: 20px;
                    border: 1px solid #e2e9f7;
                    border-radius: 20px;
                    box-shadow: 0px 30px 60px rgb(193 233 245);
                }
            }
            .announcement {
                .list {
                    margin-top: 3vw;
                    ul {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        li {
                            width: 48%;
                            position: relative;
                            margin-bottom: 2vw;
                            font-size: 1.25vw;
                            box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.16);
                            border-radius: 0 10px 0 0;
                            .date {
                                    background: #34254C;
                                    text-align: center;
                                    position: absolute;
                                    left: 0;
                                    color: #fff;
                                    height: 125px;
                                    width: 115px;
                                    padding: 20px 10px;
                                    border-radius: 0 0 40px 0;
                                    font-size: 22px;
                                p {
                                    font-size: 28px;
                                    font-weight: 600;
                                    margin-top: 10px;
                                }
                            }
                            .text {
                                padding: 10px 60px 10px 130px;
                                line-height: 180%;
                                min-height: 125px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                position: relative;
                                font-size: 18px;
                                >div {
                                    display: -webkit-box;
                                    overflow: hidden;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                }
                                .icon-more {
                                    position: absolute;
                                    right: 1vw;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .company-management {
                margin-top: 10vw;
                ul {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    margin: 3vw auto;
                    width: 100%;
                    max-width: 1000px;
                    li {
                        width: 50%;
                        height: 308px;
                        box-sizing: border-box;
                        position: relative;
                        .icon-more {
                            position: absolute;
                            bottom: 1.5vw;
                        }
                        img {
                            height: 100%;
                        }
                        &:nth-child(2),
                        &:nth-child(3) {
                            background-color: rgba(54, 45, 90, 0.9);
                            padding: 2vw;
                            color: #fff;
                            h2 {
                                font-size: 18px;
                            }
                            >p {
                                margin: 1vw 0;
                                display: -webkit-box;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 5;
                                >span {
                                    display: inline-block;
                                    width: 100%;
                                    color: #fff;
                                    font-size: 14px;
                                    line-height: 220%;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    word-break: break-all;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .h5{display: none;}
            }
            .other {
                margin-top: 8vw;
                ul {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    li {
                        text-align: center;
                        width: 25%;
                        img {
                            width: 80px;
                            height: 80px;
                        }
                        h2 {
                            font-size: 24px;
                            font-weight: 400;
                            color: #353968;
                            margin: 2vw 0 1vw;
                        }
                        p {
                            color: #353968;
                            font-size: 1.25vw;
                            margin: 1vw 0;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 750px) {
        #investor-relations{
            .icon-more{
                .fontFn(16,width);
                .fontFn(12,height)
            }
            .content{
                background-size: 750px auto;
                background-position: center -304px;
                &>div{
                    width: 100%;
                }
                .btn-more{
                    margin:10% auto;
                    .fontFn(12);
                    .fontFn(140,width);
                    .fontFn(44,height);
                    .fontFn(44,line-height);
                }
                .badge{
                    .fontFn(18);
                    &::after{
                        .fontFn(16,width);
                        .fontFn(12,height);
                        top:-10px;
                    }
                }
                .baseinfo{
                    padding-bottom: 6%;
                    p{
                        .fontFn(14);
                        .fontFn(24,line-height);
                        background:url('../../assets/images/phone/module_bg5.png') no-repeat center top;
                        background-size:100%;
                        padding: 0 2% 2%;
                    }
                    .k-line{
                        width: 96%;

                    }
                }
                .announcement{
                    .list{
                        margin-top:0;
                        padding-top: 5%;
                        ul{
                            display: block;
                            padding: 0 2%;
                            li{
                                width: 100%;
                                margin-bottom: 10%;
                                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
                                .date{
                                    .fontFn(12);
                                    width: 19.4%;
                                    height: 100%;
                                    padding: 2% 1% 2% 0;
                                    p{
                                        .fontFn(28);
                                    }
                                }
                                .text{
                                    .fontFn(12);
                                    padding: 2% 10% 2% 24%;
                                    .fontFn(80,min-height);
                                    .icon-more{right:2%;}
                                }
                            }
                        }
                    }
                } 
                .company-management{
                    background:url('../../assets/images/phone/module_bg6.png') no-repeat center top;
                    background-size: 100%;
                    padding-bottom: 10%;
                    & > h2{
                        color:#fff;
                        padding: 8% 0 4%;
                    }
                    ul{
                        width: 100%;
                        display: none;
                    }
                    .h5{
                        display: block;
                        padding: 0 2.6%;

                        & > div{
                            padding: 2.6%;
                            background-color: rgba(52,37,76,0.5);
                            margin-bottom: 3%;
                            .fontFn(250,min-height);
                            em{
                                display: block;
                                overflow: hidden;
                                .fontFn(77,height);
                                position: relative;
                                .fontFn(4,border-radius);
                                margin-bottom: 4%;
                                img{
                                    position: absolute;
                                    width: 100%;
                                    top:50%;
                                    left:0;
                                    transform: translateY(-50%);
                                    -webkit-transform: translateY(-50%);
                                    -moz-transform: translateY(-50%);
                                    -ms-transform: translateY(-50%);
                                    -o-transform: translateY(-50%);
                                }
                            }
                            h2{
                                color:#fff;
                                .fontFn(14);
                                margin-bottom: 4%;
                            }
                            p{
                                color:#DBDBDB;
                                .fontFn(12);
                                span{
                                    display: block;
                                    padding-bottom: 5%;
                                    overflow:hidden;
                                    text-overflow:ellipsis;
                                    white-space:nowrap;
                                }

                            }
                            .icon-more{
                                color:#D93C45;
                                display: inline-block;
                                width: auto;
                                .fontFn(12);
                                background-position: left center;
                                padding-left: 5%;
                                background-size:20% auto; 
                            }
                        }
                            
                    }
                }
                .other{
                    margin-bottom: 0;
                    ul{
                        display: block;
                        font-size: 0;
                        li{
                            margin-bottom: 10%;
                            display: inline-block;
                            vertical-align: top;
                            width: 50%;
                            h2{
                                padding: 6% 0;
                                .fontFn(14);
                            }
                        }
                    }
                }
            } 
        } 
    }
</style>